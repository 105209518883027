import { GeneratingMetaTagsError, SendingMetaTagsError } from '#errors'
import { endpoints, request } from '#services'

export const generateMetaTags = async (data) => {
  try {
    return await request(endpoints.metaTagsGenerator.create(data))
  } catch (error) {
    throw new GeneratingMetaTagsError(error)
  }
}

export const sendMetaTags = async (data) => {
  try {
    return await request(endpoints.meta.create(data))
  } catch (error) {
    throw new SendingMetaTagsError(error)
  }
}
