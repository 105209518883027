import { log } from '#helpers'
import { useMeta, useScan } from '#hooks'
import { helpFilled } from '@wordpress/icons'
import { Show, Tooltip } from '#components'
import { sendKeywords } from '#services/keywords'
import { currentLocale } from '#translations/i18next'
import {
  Spinner,
  Button,
  ButtonGroup,
  Icon,
  Flex,
  Modal,
} from '@wordpress/components'
import { useFormContext } from 'react-hook-form'
import { Trans as T, useTranslation } from 'react-i18next'

export const SelectKeywordModal = ({
  options,
  setOptions,
  pop,
  setIsWaiting,
  isWaiting,
  closeSelectKeywordModal,
  openSearchKeywordModal,
}) => {
  const { t } = useTranslation()

  const { mutate: mutateMeta } = useMeta({
    popId: pop.id,
    key: 'keyword',
  })
  const { mutate: mutateScan } = useScan(pop)

  const OPTIONS_SHOWN = 5

  const methods = useFormContext()

  const sendKeywordsRequest = (data) => {
    setIsWaiting(true)
    const result = options.find(({ value }) => value === data.option)
    sendKeywords({
      postId: pop.id,
      data: {
        keyword: result.value,
        keywordId: result.id,
      },
    })
      .finally(() => {
        setIsWaiting(false)
        closeSelectKeywordModal()
        setOptions([])
        methods.reset()
        mutateScan()
        mutateMeta()
      })
      .catch(log)
  }

  return (
    <Modal
      title={t('sidebar.task.keyword.modal.select.title')}
      className="mktseo mktseo-modal--black"
      shouldCloseOnClickOutside={false}
      onRequestClose={closeSelectKeywordModal}
    >
      <p>{t('sidebar.task.keyword.modal.select.content1')}</p>
      <p>
        <T
          i18nKey="sidebar.task.keyword.modal.select.content2"
          values={{ search: methods.watch('keywords') }}
          components={[<strong key={0} />]}
        />
      </p>
      <Show when={isWaiting && !options.length}>
        <Spinner />
      </Show>
      <Show when={options.length}>
        <form onSubmit={methods.handleSubmit(sendKeywordsRequest)}>
          <table className="focus-keyword__table">
            <thead>
              <tr>
                <th>{t('sidebar.task.keyword.modal.select.table.keywords')}</th>
                <th>
                  {t('sidebar.task.keyword.modal.select.table.volume')}
                  <Tooltip
                    text={t(
                      'sidebar.task.keyword.modal.select.table.volume.tooltip',
                    )}
                  >
                    <Icon icon={helpFilled} />
                  </Tooltip>
                </th>
              </tr>
            </thead>
            <tbody>
              {options.slice(0, OPTIONS_SHOWN).map((result) => (
                <tr
                  key={result.id}
                  className={
                    methods.watch('option') === result.value ? 'checked' : ''
                  }
                  onClick={() => {
                    methods.setValue('option', result.value)
                  }}
                >
                  <td>
                    <input
                      {...methods.register('option')}
                      value={result.value}
                      type="radio"
                    />
                    {result.value}
                  </td>
                  <td>
                    {Intl.NumberFormat(currentLocale, {
                      notation: 'compact',
                      compactDisplay: 'short',
                    }).format(result.searchVolume)}
                  </td>
                </tr>
              ))}
              {options.length < OPTIONS_SHOWN
                ? [...new Array(OPTIONS_SHOWN - options.length)].map(
                    (_, index) => (
                      <tr className="empty" key={index}>
                        <td>
                          <input type="radio" disabled />
                          {t('sidebar.task.keyword.modal.select.table.empty')}
                        </td>
                        <td>0</td>
                      </tr>
                    ),
                  )
                : null}
            </tbody>
          </table>
          <Show when={options.length < 6}>
            <p>{t('sidebar.task.keyword.modal.select.table.helper')}</p>
          </Show>
          <ButtonGroup>
            <Flex gap="5" justify="end">
              <Button
                isBusy={isWaiting}
                disabled={isWaiting}
                onClick={() => {
                  closeSelectKeywordModal()
                  openSearchKeywordModal()
                  setOptions([])
                  methods.setValue('option', '')
                }}
                variant="secondary"
              >
                {t('sidebar.task.keyword.modal.select.button.edit')}
              </Button>
              <Button
                isBusy={isWaiting}
                disabled={!methods.watch('option') || isWaiting}
                type="submit"
                variant="primary"
              >
                {t('sidebar.task.keyword.modal.select.button.add')}
              </Button>
            </Flex>
          </ButtonGroup>
        </form>
      </Show>
    </Modal>
  )
}
