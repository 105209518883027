import { AutoOptimize } from '#autooptimize'
import { EmptyPost, Loader, SeoScore, UIBlocker } from '#components'
import { useMeta, useScan, useTokenStatus } from '#hooks'
import { Schema } from '#schema'
import { getPop } from '#services/getPop'
import { Tasks } from '#tasks'
import { Advanced } from '#advanced'
import { FocusKeyword } from '#tasks/FocusKeyword'
import { TabPanel } from '@wordpress/components'
import { useEffect, useState } from '@wordpress/element'
import { useTranslation } from 'react-i18next'

import './tasks-styles.css'

export const Sidebar = () => {
  const { t } = useTranslation()
  const [pop, setPop] = useState({ id: null, type: '', status: 'draft' })
  const { data: tokenStatus } = useTokenStatus()
  const { isValidating: isValidatingScan } = useScan(pop)
  const { data: keywordSelected, isValidating: isValidatingKeyword } = useMeta({
    popId: pop.id,
    key: 'keyword',
  })

  const [isWaiting, setIsWaiting] = useState({
    publishAndCheck: false,
    optimize: false,
  })

  const [isManualOptimization, setIsManualOptimization] = useState(true)
  const [isOptimized, setIsOptimized] = useState(false)

  useEffect(() => {
    setIsWaiting({
      ...isWaiting,
      publishAndCheck: isValidatingScan,
    })
  }, [isValidatingScan])

  useEffect(() => {
    getPop(setPop)
  }, [window.location.search])

  // TODO: avoid call at useTasks thanks to status at Elementor
  if (pop.status === 'draft') return <EmptyPost />

  if (tokenStatus?.success === false)
    return <UIBlocker tokenStatus={tokenStatus?.message} />

  if (isValidatingKeyword) return <Loader />

  return (
    <div className="mktseo">
      {!keywordSelected ? (
        <FocusKeyword.Unselected pop={pop} />
      ) : (
        <>
          <SeoScore pop={pop} isBusy={isWaiting.publishAndCheck} />
          <TabPanel
            tabs={[
              isManualOptimization
                ? {
                    name: 'tasks',
                    title: t('sidebar.task.title'),
                    content: (
                      <>
                        {isWaiting.optimize ? (
                          <Loader />
                        ) : (
                          <Tasks
                            pop={pop}
                            isWaiting={isWaiting}
                            isOptimized={isOptimized}
                            setIsWaiting={setIsWaiting}
                            setIsManualOptimization={setIsManualOptimization}
                          />
                        )}
                      </>
                    ),
                  }
                : {
                    name: 'optimization',
                    title: t('sidebar.task.title'),
                    content: (
                      <>
                        <AutoOptimize
                          pop={pop}
                          setIsOptimized={setIsOptimized}
                          setIsManualOptimization={setIsManualOptimization}
                        />
                      </>
                    ),
                  },
              {
                name: 'schema',
                title: t('sidebar.schema.title'),
                content: <Schema pop={pop} />,
              },
              {
                name: 'advanced',
                title: t('sidebar.advanced.title'),
                content: <Advanced pop={pop} />,
              },
            ]}
          >
            {(tab) => <>{tab.content}</>}
          </TabPanel>
        </>
      )}
    </div>
  )
}
