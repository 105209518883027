import useSWRImmutable from 'swr/immutable'
import { endpoints, request } from '#services'

export const useCtas = () => {
  const { data, isLoading, isValidating, error } = useSWRImmutable(
    endpoints.ctas.read(),
    request,
  )

  return {
    data: data?.data?.data,
    isLoading,
    isValidating,
    error,
  }
}
