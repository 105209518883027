export class GetPageError extends Error {
  constructor(message) {
    super(`Error getting page ${message}`)
    this.name = 'getPageError'
  }
}

export class GetPostError extends Error {
  constructor(message) {
    super(`Error getting post ${message}`)
    this.name = 'getPostError'
  }
}
