import '#components/Level/styles.css'

export const Level = ({ variant = 'low', size = 'medium' }) => {
  const styles = [
    'ola_level',
    variant ? `is-${variant}` : '',
    `is-size_${size}`,
  ].join(' ')

  return (
    <span className={styles}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </span>
  )
}

// Level.propTypes = {
//   variant: PT.oneOf(['low', 'medium', 'high', 'highest']),
//   size: PT.oneOf(['small', 'medium', 'big']),
// }
