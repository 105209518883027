import { Frame, Level } from '#components'
import { useScan } from '#hooks'
import { Flex } from '@wordpress/components'
import { Trans as T } from 'react-i18next'

import '#components/SeoScore/styles.css'

const getLevelFromScore = (score) => {
  const normalizedScore = typeof score === 'string' ? parseInt(score) : score
  if ((!normalizedScore && normalizedScore !== 0) || normalizedScore < 50)
    return 'low'
  if (normalizedScore === 100) return 'highest'
  if (normalizedScore >= 85) return 'high'
  return 'medium'
}

export const SeoScore = ({ pop, isBusy }) => {
  const { data: scan } = useScan(pop)
  const variant = isBusy ? 'busy' : getLevelFromScore(scan?.score)

  return (
    <Frame className={`seo-score is-${variant}`}>
      <Flex direction="column">
        <Level variant={variant} />
        <span>
          <T i18nKey="sidebar.score" />
        </span>
      </Flex>
    </Frame>
  )
}
