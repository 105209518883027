import { Check } from '#components'
import { useScan } from '#hooks'
import { Flex, PanelBody } from '@wordpress/components'
import { useTranslation } from 'react-i18next'

import './styles.css'

export const KeywordInASubheader = ({ pop }) => {
  const { t } = useTranslation()
  const { data: scan, isValidating } = useScan(pop)
  const condition = scan?.tasks?.['keyonhx'].pass

  return (
    <PanelBody
      title={
        <Flex justify="flex-start">
          <Check condition={condition} isValidating={isValidating} />
          <p className="task-title">{t('sidebar.task.subheader.header')}</p>
        </Flex>
      }
      initialOpen={!condition}
    >
      <div className="keyword-in-a-subheader task-content">
        {condition ? (
          <p>{t('sidebar.task.subheader.title.completed')}</p>
        ) : (
          <>
            <p>{t('sidebar.task.subheader.content.1')}</p>
            <p>{t('sidebar.task.subheader.content.2')}</p>
            <p>{t('sidebar.task.subheader.content.3')}</p>
          </>
        )}
      </div>
    </PanelBody>
  )
}
