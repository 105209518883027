export class SearchKeywordsError extends Error {
  constructor(message) {
    super(`Error searching keywords: ${message}`)
    this.name = 'SearchKeywordsError '
  }
}

export class SendKeywordsError extends Error {
  constructor(message) {
    super(`Error sending keywords: ${message}`)
    this.name = 'SendKeywordsError '
  }
}
