import { Button, CheckboxControl, Tooltip } from '@wordpress/components'
import { closeSmall } from '@wordpress/icons'
import { useTranslation } from 'react-i18next'

import './styles.css'

export const ContentCheck = ({
  title,
  content,
  onCloseButton,
  onChange,
  checked,
}) => {
  const { t } = useTranslation()
  return (
    <article className={`content-check${checked ? ' checked' : ''}`}>
      <header>
        <CheckboxControl onChange={onChange} checked={checked} />
        <div className="content-check__title">
          <b>{title}</b>
          <Tooltip
            delay="300"
            placement="top"
            text={t('sidebar.autooptimize.discard.text')}
          >
            <Button onClick={onCloseButton} icon={closeSmall} />
          </Tooltip>
        </div>
      </header>
      {content}
    </article>
  )
}
