export const log = (data) => {
  console.log(data)
}

export const decode = (str) => {
  let txt = new DOMParser().parseFromString(str, 'text/html')
  return txt.documentElement.textContent
}

export const removeSpecialChars = (string) =>
  string.replace(/[^a-zA-Z0-9\s]/g, '')

export const formatDate = (date) => {
  const newDate = new Date(date)
  const day = String(new Date(newDate).getDate()).padStart(2, '0')
  const month = String(new Date(newDate).getMonth() + 1).padStart(2, '0')
  const year = new Date(newDate).getFullYear()

  return `${day}/${month}/${year}`
}

export const parseDate = (dateString) => {
  const parts = dateString.split('/')
  const dateObject = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`)

  if (isNaN(dateObject.getTime())) {
    return null
  }

  return dateObject
}

// La función generateHash se define fuera del componente.
export const generateHash = async (message) => {
  const msgBuffer = new TextEncoder().encode(message)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}
