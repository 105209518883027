import { Flex, Spinner } from '@wordpress/components'

export const Loader = () => {
  return (
    <Flex justify="center" style={{ height: '50vh' }}>
      <Spinner
        style={{
          height: '60px',
          width: '60px',
          position: 'initial',
        }}
      />
    </Flex>
  )
}
