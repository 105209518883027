import { Selected } from '#tasks/FocusKeyword/Selected'
import { Unselected } from '#tasks/FocusKeyword/Unselected'

Selected.displayName = 'FocusKeyword.Selected'
Unselected.displayName = 'FocusKeyword.Unselected'

export const FocusKeyword = {
  Selected,
  Unselected,
}
