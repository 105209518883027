import { Frame, LimitReachedModal, Show, UpgradeModal } from '#components'
import { useCtas, useScan } from '#hooks'
import { EditorContext } from '#services/EditorContext'
import {
  clickAutoOptimizeAtSidebarTaskEvent,
  viewSidebarTabTaskEvent,
} from '#services/events'
import { Button } from '@wordpress/components'
import { useContext, useEffect, useState } from '@wordpress/element'
import { Trans as T } from 'react-i18next'

import { EnoughWords } from '#tasks/EnoughWords'
import { FocusKeyword } from '#tasks/FocusKeyword'
import { ImagesAltText } from '#tasks/ImagesAltText'
import { KeywordInASubheader } from '#tasks/KeywordInASubheader'
import { MetaTags } from '#tasks/MetaTags'
import { PerfectTitle } from '#tasks/PerfectTitle'

export const Tasks = ({
  pop,
  isWaiting,
  isOptimized,
  setIsWaiting,
  setIsManualOptimization,
}) => {
  const { data: scan, mutate, isValidating: isValidatingScan } = useScan(pop)

  const triesLeft = scan?.tasks?.['keyontitle'].triesLeft

  const { data: availableCtas } = useCtas()

  const upgradeCta = availableCtas?.find(
    (cta) => cta.id === 'wordpress-upgrade',
  )

  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const [showLimitReachedModal, setShowLimitReachedModal] = useState(false)

  const getPublishButton = () => {
    const gutenbergPublishButton = document.querySelector(
      '.editor-post-publish-button',
    )

    const elementorPublishButton = document.querySelector(
      '#elementor-panel-saver-button-publish',
    )

    const classicAndBoldgridEditorPublishButton =
      document.querySelector('#publish')

    return (
      gutenbergPublishButton ||
      elementorPublishButton ||
      classicAndBoldgridEditorPublishButton
    )
  }

  const editor = useContext(EditorContext)

  const publishAndCheck = () => {
    const publishButton = getPublishButton()
    if (
      publishButton.getAttribute('aria-disabled') === 'true' &&
      !publishButton.classList.contains('is-busy')
    ) {
      mutate()
    } else {
      publishButton.click()
      setIsWaiting({ ...isWaiting, publishAndCheck: true })
    }
  }

  const optimizeService = () => {
    clickAutoOptimizeAtSidebarTaskEvent()
    if (upgradeCta) {
      setShowUpgradeModal(true)
    } else {
      if (triesLeft === 0) {
        setShowLimitReachedModal(true)
      } else {
        setIsManualOptimization(false)
      }
    }
  }

  useEffect(() => {
    viewSidebarTabTaskEvent()
  }, [])

  useEffect(() => {
    setIsWaiting({
      ...isWaiting,
      publishAndCheck: isValidatingScan,
    })
  }, [isValidatingScan])

  useEffect(() => {
    if (isOptimized) {
      publishAndCheck()
    }
  }, [isOptimized])

  useEffect(() => {
    const publishButton = getPublishButton()

    const observer = new MutationObserver((mutations) => {
      mutations.forEach(({ attributeName }) => {
        const isAriaDisabled =
          publishButton.getAttribute('aria-disabled') === 'true'
        const hasBusyClass = publishButton.classList.contains('is-busy')

        if (isAriaDisabled && !hasBusyClass) {
          mutate()
        }

        const elementorReady =
          editor === 'elementor' &&
          attributeName === 'class' &&
          publishButton
            .getAttribute(attributeName)
            .includes('elementor-disabled')

        if (elementorReady) {
          mutate()
          console.log('publish button ready')
        }
      })
    })

    if (publishButton) {
      observer.observe(publishButton, {
        attributes: true,
        attributeFilter: ['class', 'aria-disabled'],
      })

      return () => {
        observer.disconnect()
      }
    }
  }, [])

  return (
    <>
      <section className="auto-optimize">
        <Frame className="auto-optimize__buttons">
          <p>
            <T i18nKey="sidebar.autooptimize.cta.pre.text" />
          </p>
          <Button
            className="auto-optimize__magic-button"
            variant="primary"
            isBusy={isWaiting.publishAndCheck}
            disabled={isWaiting.publishAndCheck}
            onClick={optimizeService}
          >
            <i class="ph-fill ph-magic-wand" />
            <T i18nKey="sidebar.autooptimize.cta.pre.button" />
          </Button>
        </Frame>
      </section>
      <FocusKeyword.Selected pop={pop} />
      <PerfectTitle pop={pop} />
      <KeywordInASubheader pop={pop} />
      <MetaTags pop={pop} isWaiting={isWaiting} />
      <ImagesAltText pop={pop} />
      <EnoughWords pop={pop} />
      <Frame>
        <b>
          <p>
            <T i18nKey="sidebar.task.check.title" />
          </p>
        </b>
        <p>
          <T i18nKey="sidebar.task.check.content" />
        </p>
        <Button
          isBusy={isWaiting.publishAndCheck}
          disabled={isWaiting.publishAndCheck}
          variant="primary"
          onClick={publishAndCheck}
        >
          <T i18nKey="sidebar.task.check.button" />
        </Button>
      </Frame>
      <Show when={showUpgradeModal}>
        <UpgradeModal closeModal={() => setShowUpgradeModal(false)} />
      </Show>
      <Show when={showLimitReachedModal}>
        <LimitReachedModal closeModal={() => setShowLimitReachedModal(false)} />
      </Show>
    </>
  )
}
