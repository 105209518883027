import { useState } from '@wordpress/element'

const styles = {
  mktgoodraggable: {
    position: 'absolute',
    zIndex: 9,
    backgroundColor: '#6c2eb9',
    border: '1px solid #d3d3d3',
    textAlign: 'center',
    width: '300px',
  },
  mktgoodraggableheader: {
    padding: '10px',
    cursor: 'move',
    zIndex: 10,
    backgroundColor: '#6c2eb9',
    color: '#fff',
  },
  mktgoodraggablecontent: {
    overflowY: 'scroll',
    maxHeight: '70vh',
  },
}

export const Draggable = ({ children }) => {
  const [x, setX] = useState('50px')
  const [y, setY] = useState('50px')

  const handleDragEnd = (event) => {
    setX(event.clientX)
    setY(event.clientY)
  }

  return (
    <div
      id="mktgoodraggable"
      style={{ ...styles.mktgoodraggable, left: x, top: y }}
      onDragEnd={handleDragEnd}
      draggable
    >
      <div id="mktgoodraggableheader" style={styles.mktgoodraggableheader}>
        {window.marketgoo.pluginName}
      </div>
      <div style={styles.mktgoodraggablecontent}>{children}</div>
    </div>
  )
}
