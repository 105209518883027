import { Icon } from '@wordpress/components'
import { closeSmall } from '@wordpress/icons'

import './styles.css'

export const TokenLabel = ({ children, onClick }) => {
  return (
    <span className="token-label">
      <span>{children}</span>{' '}
      {onClick ? (
        <Icon
          onClick={onClick}
          className="close-icon"
          size={24}
          icon={closeSmall}
        />
      ) : null}
    </span>
  )
}
