import { Check } from '#components'
import { usePop } from '#hooks'
import { Flex, Notice, PanelBody, Spinner } from '@wordpress/components'
import { useTranslation } from 'react-i18next'

export const ImagesAltText = ({ pop }) => {
  const { t } = useTranslation()
  const parser = new DOMParser()

  const { data: post, isError, isLoading, isValidating } = usePop(pop)

  if (isError) {
    return <Notice status="error">{isError}</Notice>
  }

  if (isLoading) {
    return <Spinner />
  }

  if (!post) {
    return null
  }

  const contentHTMLCollection = parser.parseFromString(
    post.content.rendered,
    'text/html',
  )

  const images = Array.from(contentHTMLCollection.images).filter(
    (image) => !image.alt,
  )

  return (
    <PanelBody
      title={
        <Flex justify="flex-start">
          <Check condition={!images?.length} isValidating={isValidating} />
          <p className="task-title">{t('sidebar.task.alttext.header')}</p>
        </Flex>
      }
      initialOpen={images?.length > 0}
    >
      {images.length ? (
        <>
          <p className="task-content">{t('sidebar.task.alttext.intro')}</p>
          <p className="task-content">{t('sidebar.task.alttext.intro2')}</p>

          <ul>
            {images.map((image, index) => (
              <li key={index}>
                <img alt="" width="100px" src={image.src} />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <p className="task-content">
          {t('sidebar.task.alttext.title.completed')}
        </p>
      )}
    </PanelBody>
  )
}
