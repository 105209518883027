import { log } from '#helpers'
import { usePop } from '#hooks'
import { searchKeywords } from '#services/keywords'
import {
  Button,
  ButtonGroup,
  Flex,
  Modal,
  TextControl,
} from '@wordpress/components'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const SearchKeywordModal = ({
  pop,
  setOptions,
  setIsWaiting,
  closeSearchKeywordModal,
  openSelectKeywordModal,
}) => {
  const { t } = useTranslation()
  const { data: post } = usePop(pop)

  const methods = useFormContext()

  const searchKeywordsRequest = (data) => {
    setIsWaiting(true)
    closeSearchKeywordModal()
    openSelectKeywordModal()
    searchKeywords(data.keywords)
      .then(({ data: { data, success } }) => {
        if (success) {
          const [first, ...others] = data.attributes.results
          setOptions([
            {
              label: first.name,
              value: first.name,
              searchVolume: first.searchVolume,
              id: first.id,
            },
            ...others
              .map((result) => ({
                label: result.name,
                value: result.name,
                searchVolume: result.searchVolume,
                id: result.id,
              }))
              .sort((a, b) => a.searchVolume < b.searchVolume),
          ])
        }
      })
      .then(() => {
        methods.setValue('option', data.keywords)
      })
      .finally(() => {
        setIsWaiting(false)
      })
      .catch(log)
  }

  const textCleaner = (htmlText) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = htmlText
    return tempElement.textContent || tempElement.innerText || ''
  }

  return (
    <Modal
      title={t('sidebar.task.keyword.modal.select.title')}
      className="mktseo mktseo-modal--black"
      shouldCloseOnClickOutside={false}
      onRequestClose={closeSearchKeywordModal}
    >
      <form onSubmit={methods.handleSubmit(searchKeywordsRequest)}>
        <p> {t('sidebar.task.keyword.modal.search.content1')} </p>
        <p>
          {t('sidebar.task.keyword.modal.search.content2', {
            title: textCleaner(post?.title?.rendered),
          })}
        </p>
        <Controller
          name="keywords"
          control={methods.control}
          render={({ field, fieldState }) => (
            <TextControl
              {...field}
              autoFocus
              placeholder={t(
                'sidebar.task.keyword.modal.search.input.placeholder',
              )}
              className={fieldState.invalid ? 'form-error' : ''}
              help={fieldState.error?.message || null}
            />
          )}
          rules={{
            required: {
              value: true,
              message: t('sidebar.task.keyword.modal.search.input.error'),
            },
          }}
        />
        <ButtonGroup>
          <Flex gap="5" justify="end">
            <Button type="submit" variant="primary">
              {t('sidebar.task.keyword.modal.search.button')}
            </Button>
          </Flex>
        </ButtonGroup>
      </form>
    </Modal>
  )
}
