import { TokenLabel } from '#components'
import { log } from '#helpers'
import { useMeta, usePop, useRecommendedKeyword, useScan } from '#hooks'
import { sendKeywords } from '#services/keywords'
import {
  Button,
  ButtonGroup,
  Flex,
  Modal,
  Spinner,
} from '@wordpress/components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const RecommendedKeyword = ({
  closeRecommendedKeywordModal,
  openSearchKeywordModal,
  setIsWaiting,
  isWaiting,
  setOptions,
  pop,
}) => {
  const { t } = useTranslation()

  const {
    data: {
      link,
      title: { rendered: title },
    },
  } = usePop(pop)

  const {
    data: recommendedKeywords,
    isLoading,
    isValidating,
  } = useRecommendedKeyword(pop)
  const [{ attributes: keyword } = {}] = recommendedKeywords || []

  const { mutate: mutateMeta } = useMeta({
    popId: pop.id,
    key: 'keyword',
  })

  const { mutate: mutateScan } = useScan(pop)

  const methods = useFormContext()

  const nextStep = () => {
    closeRecommendedKeywordModal()
    openSearchKeywordModal()
  }

  const sendRecommendedKeywordRequest = () => {
    setIsWaiting(true)
    sendKeywords({
      postId: pop.id,
      data: {
        keyword: keyword,
        keywordId: keyword,
      },
    })
      .finally(() => {
        setIsWaiting(false)
        closeRecommendedKeywordModal()
        setOptions([])
        methods.reset()
        mutateScan()
        mutateMeta()
      })
      .catch(log)
  }

  const transformHtmlEntities = (html) => {
    const parser = new DOMParser()
    const rendered = parser.parseFromString(html, 'text/html')

    return rendered.documentElement.textContent
  }

  return (
    <Modal
      title={t('sidebar.keywordautomate.modal.title')}
      className="mktseo mktseo-modal--black"
      shouldCloseOnClickOutside={false}
      onRequestClose={closeRecommendedKeywordModal}
    >
      <p>{t('sidebar.keywordautomate.modal.text')}</p>
      <p>
        <b>{t('sidebar.keywordautomate.modal.pagetitle.label')}</b>
        <span>{` ${transformHtmlEntities(title)}`}</span>
      </p>
      <p>
        <b>{t('sidebar.keywordautomate.modal.url.label')}</b>
        {` ${link}`}
      </p>
      <p>
        <b>{t('sidebar.keywordautomate.modal.keyword.label')}</b>
        {isLoading || isValidating ? (
          <Spinner />
        ) : (
          <TokenLabel>{keyword}</TokenLabel>
        )}
      </p>
      <ButtonGroup>
        <Flex gap="5" justify="end">
          <Button
            type="submit"
            variant="secondary"
            disabled={isWaiting}
            isBusy={isWaiting}
            onClick={nextStep}
          >
            {t('sidebar.keywordautomate.modal.button.manually')}
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={isWaiting}
            isBusy={isWaiting}
            onClick={sendRecommendedKeywordRequest}
          >
            {t('sidebar.keywordautomate.modal.button.optimize')}
          </Button>
        </Flex>
      </ButtonGroup>
    </Modal>
  )
}
