import { SendingEventError } from '#errors'
import { endpoints, request } from '#services'

export const eventNames = {
  USAGE: 'usage',
}

const placements = {
  SIDEBAR_TAB_TASK: 'sidebar.tab.task',
  SIDEBAR_TAB_SCHEMA: 'sidebar.tab.schema',
  SIDEBAR_TAB_ADVANCED: 'sidebar.tab.advanced',
  EDITOR: 'editor',
}
const elements = {
  AUTO_OPTIMIZE_CTA: 'auto.optimize.cta',
  UPGRADE_CTA: 'upgrade.cta',
  LAUNCH_BUTTON: 'launch.button',
}

const createEvent = async (action, event, placement, element = null) => {
  try {
    const data = {
      action,
      event: `wordpress.${event}`,
      placement,
      ...(element && { element }),
    }
    return await request(endpoints.events.create(data))
  } catch (error) {
    throw new SendingEventError(error)
  }
}

export const viewEvent = async (placement) =>
  await createEvent('view', eventNames.USAGE, placement)

export const viewEventGenerator = (placement) => async () =>
  await viewEvent(placement)

export const viewSidebarTabTaskEvent = viewEventGenerator(
  placements.SIDEBAR_TAB_TASK,
)

export const viewSidebarTabSchemaEvent = viewEventGenerator(
  placements.SIDEBAR_TAB_SCHEMA,
)

export const viewSidebarTabAdvancedEvent = viewEventGenerator(
  placements.SIDEBAR_TAB_ADVANCED,
)

export const clickEvent = (element) => async (placement) =>
  await createEvent('click', eventNames.USAGE, placement, element)

const clickEventGenerator = (element) => (placement) => async () => {
  await createEvent('click', eventNames.USAGE, placement, element)
}

export const clickAutoOptimizeAtSidebarTaskEvent = clickEventGenerator(
  elements.AUTO_OPTIMIZE_CTA,
)(placements.SIDEBAR_TAB_TASK)

export const clickUpgradeAtSidebarAdvancedEvent = clickEventGenerator(
  elements.UPGRADE_CTA,
)(placements.SIDEBAR_TAB_ADVANCED)

export const clickEditorLaunchButtonEvent = clickEventGenerator(
  elements.LAUNCH_BUTTON,
)(placements.EDITOR)
