import useSWRImmutable from 'swr/immutable'
import { endpoints, request } from '#services'
import { generateHash } from '#helpers'
import { useEffect, useState } from '@wordpress/element'

export const useRecommendedKeyword = ({ id, status, type }) => {
  const [hashedMessage, setHashedMessage] = useState(null)

  const { data: post } = useSWRImmutable(
    id && status === 'publish' ? endpoints.pop.get({ id, type }) : null,
    request,
  )

  useEffect(() => {
    if (post?.data?.slug) {
      generateHash(`${type}${id}${post.data.slug}`).then(setHashedMessage)
    }
  }, [post])

  const { data, isLoading, isValidating, mutate, error } = useSWRImmutable(
    hashedMessage
      ? endpoints.recommendedKeyword.read({
          hash: hashedMessage,
          data: { slug: post?.data?.slug },
        })
      : null,
    request,
  )

  return {
    data: data?.data?.data,
    isLoading,
    isValidating,
    mutate,
    error,
  }
}
