import { Check } from '#components'
import { usePop } from '#hooks'
import { Flex, Notice, PanelBody, Spinner } from '@wordpress/components'
import { useTranslation, Trans as T } from 'react-i18next'

const htmlWordCounter = (html) => {
  const parser = new DOMParser()
  const rendered = parser.parseFromString(html, 'text/html')

  return rendered.documentElement.textContent
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ').length
}

export const EnoughWords = ({ pop }) => {
  const { t } = useTranslation()

  const WORDS = 300
  const { data: post, isError, isLoading, isValidating } = usePop(pop)

  if (isError) {
    return <Notice status="error">{isError}</Notice>
  }

  if (isLoading) {
    return <Spinner />
  }

  if (!post) {
    return null
  }

  const wordsCount =
    htmlWordCounter(post.title.rendered) +
    htmlWordCounter(post.content.rendered)

  return (
    <PanelBody
      title={
        <Flex justify="flex-start">
          <Check condition={wordsCount >= WORDS} isValidating={isValidating} />
          <p className="task-title">{t('sidebar.task.enoughwords.title')}</p>
        </Flex>
      }
      initialOpen={wordsCount < WORDS}
    >
      <div className="task-content">
        {wordsCount >= WORDS ? (
          <p>{t('sidebar.task.enoughwords.completed')}</p>
        ) : (
          <>
            <p>{t('sidebar.task.enoughwords.intro.1', { count: WORDS })}</p>
            <p>
              <T
                i18nKey="sidebar.task.enoughwords.intro.2"
                values={{ number: wordsCount }}
                components={[<strong key={0} />]}
              />
            </p>
          </>
        )}
      </div>
    </PanelBody>
  )
}
