import { SearchKeywordsError, SendKeywordsError } from '#errors'
import { endpoints, request } from '#services'

export const searchKeywords = async (keywords) => {
  try {
    return await request(endpoints.keywordSearch.read({ keywords }))
  } catch (error) {
    throw new SearchKeywordsError(error)
  }
}

export const sendKeywords = async (keywords) => {
  try {
    return await request(endpoints.meta.create(keywords))
  } catch (error) {
    throw new SendKeywordsError(error)
  }
}
