import useSWRImmutable from 'swr/immutable'
import { endpoints, request } from '#services'

export const useMeta = ({ popId, key }) => {
  const { data, isLoading, isValidating, mutate, error } = useSWRImmutable(
    popId && key ? endpoints.meta.read({ popId, key }) : null,
    request,
  )

  return {
    data: data?.data?.data,
    isLoading,
    isValidating,
    mutate,
    error,
  }
}
