import { Button } from '@wordpress/components'
import { useTranslation } from 'react-i18next'
import { Trans as T } from 'react-i18next'

import '#components/UIBlocker/styles.css'

export const UIBlocker = ({ tokenStatus }) => {
  const { t } = useTranslation()

  const { pluginName, supportEmail, partnerPath } = window.marketgoo

  const ERROR_TYPES = {
    INCORRECT_DOMAIN: 'INCORRECT_DOMAIN',
    DELETED_ACCOUNT: 'DELETED_ACCOUNT',
  }

  const ERRORS = {
    INCORRECT_DOMAIN: {
      title: 'generalerror.modal.invalid.title',
      content: [
        'generalerror.modal.invalid.content.1',
        'generalerror.modal.invalid.content.2',
      ],
      ctaText: 'generalerror.modal.invalid.cta',
      cta: `mailto: ${supportEmail}`,
    },
    DELETED_ACCOUNT: {
      title: 'generalerror.modal.canceled.title',
      content: ['generalerror.modal.canceled.content'],
      ctaText: 'generalerror.modal.canceled.cta',
      cta: partnerPath,
    },
  }[tokenStatus] || {
    title: 'generalerror.modal.invalid.subscription.title',
    content: ['generalerror.modal.invalid.content.2'],
    ctaText: 'generalerror.modal.invalid.cta',
    cta: `mailto: ${supportEmail}`,
  }

  return (
    <div className="mktseo-notice--blocker">
      <h2>{<T i18nKey={`${ERRORS.title}`} />}</h2>
      {ERRORS.content.map((text, index) => (
        <p key={index}>
          <T i18nKey={text} values={{ pluginName }} />
        </p>
      ))}
      <Button variant="primary" href={ERRORS.cta}>
        <T i18nKey={ERRORS.ctaText} />
      </Button>
    </div>
  )
}
