export class GeneratingMetaTagsError extends Error {
  constructor(message) {
    super(`Error generating meta tags ${message}`)
    this.name = 'GeneratingMetaTagsError'
  }
}

export class SendingMetaTagsError extends Error {
  constructor(message) {
    super(`Error sending meta tags ${message}`)
    this.name = 'SendingMetaTagsError'
  }
}
