import { useProductDetails } from '#hooks'
import { Modal } from '@wordpress/components'
import { addDays, differenceInDays, endOfMonth } from 'date-fns'
import { useTranslation } from 'react-i18next'

export const LimitReachedModal = ({ closeModal }) => {
  const { t } = useTranslation()
  const { data: productDetails } = useProductDetails()
  const pages = productDetails?.features.optimizeSuggestions

  const days = differenceInDays(addDays(endOfMonth(new Date()), 1), new Date())

  return (
    <Modal
      title={t('sidebar.autooptimize.modal.title')}
      clasName="mktseo-modal--black"
      onRequestClose={closeModal}
    >
      <p>{t('sidebar.autooptimize.modal.text', { pages, days })}</p>
    </Modal>
  )
}
