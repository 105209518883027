import { Details, Show } from '#components'
import { useProductDetails } from '#hooks'
import { Button, PanelRow } from '@wordpress/components'
import { useState } from '@wordpress/element'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RecommendedKeyword } from './RecommendedKeyword'
import { SearchKeywordModal } from './SearchKeywordModal'
import { SelectKeywordModal } from './SelectKeywordModal'

import './styles.css'

export const Unselected = ({ pop }) => {
  const { t } = useTranslation()
  const { data: productDetails, isLoading } = useProductDetails()
  const [isWaiting, setIsWaiting] = useState(false)

  const [showSearchKeywordModal, setShowSearchKeywordModal] = useState(false)
  const openSearchKeywordModal = () => setShowSearchKeywordModal(true)
  const closeSearchKeywordModal = () => setShowSearchKeywordModal(false)

  const [showSelectKeywordModal, setShowSelectKeywordModal] = useState(false)
  const openSelectKeywordModal = () => setShowSelectKeywordModal(true)
  const closeSelectKeywordModal = () => setShowSelectKeywordModal(false)

  const [showRecommendedKeywordModal, setShowRecommendedKeywordModal] =
    useState(false)
  const openRecommendedKeywordModal = () => setShowRecommendedKeywordModal(true)
  const closeRecommendedKeywordModal = () =>
    setShowRecommendedKeywordModal(false)

  const [options, setOptions] = useState([])

  const defaultValues = { keywords: '', option: '' }
  const methods = useForm({
    defaultValues,
  })

  return (
    <PanelRow>
      <FormProvider {...methods}>
        <div className="focus-keyword unselected">
          <p className="focus-keyword__title">
            {t('sidebar.task.keyword.title')}
          </p>
          <p>{t('sidebar.task.keyword.description')}</p>
          <Button
            isBusy={isLoading}
            onClick={
              !productDetails?.free
                ? openRecommendedKeywordModal
                : openSearchKeywordModal
            }
            variant="primary"
          >
            {t('sidebar.task.keyword.button')}
          </Button>
          <Details summary={t('sidebar.task.keyword.question')}>
            <p>{t('sidebar.task.keyword.answer')}</p>
          </Details>
          <Show when={showRecommendedKeywordModal}>
            <RecommendedKeyword
              closeRecommendedKeywordModal={closeRecommendedKeywordModal}
              openSearchKeywordModal={openSearchKeywordModal}
              setIsWaiting={setIsWaiting}
              isWaiting={isWaiting}
              setOptions={setOptions}
              pop={pop}
            />
          </Show>
          <Show when={showSearchKeywordModal}>
            <SearchKeywordModal
              closeSearchKeywordModal={closeSearchKeywordModal}
              openSelectKeywordModal={openSelectKeywordModal}
              setIsWaiting={setIsWaiting}
              setOptions={setOptions}
              pop={pop}
            />
          </Show>
          <Show when={showSelectKeywordModal}>
            <SelectKeywordModal
              setIsWaiting={setIsWaiting}
              isWaiting={isWaiting}
              closeSelectKeywordModal={closeSelectKeywordModal}
              openSearchKeywordModal={openSearchKeywordModal}
              options={options}
              setOptions={setOptions}
              pop={pop}
            />
          </Show>
        </div>
      </FormProvider>
    </PanelRow>
  )
}
