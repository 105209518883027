import useSWRImmutable from 'swr/immutable'
import { endpoints, request } from '#services'

export const useTokenStatus = () => {
  const { data, isLoading, isValidating, error } = useSWRImmutable(
    endpoints.tokenStatus.read(),
    request,
  )
  return {
    data: data?.data,
    isLoading,
    isValidating,
    error,
  }
}
