import { Button, Flex, Icon } from '@wordpress/components'
import { useState } from '@wordpress/element'
import { desktop, mobile } from '@wordpress/icons'
import { Trans as T } from 'react-i18next'

import './styles.css'

export const ResponsivePreview = ({ title, link, description }) => {
  const [isMobile, setIsMobile] = useState(false)

  return (
    <article className={`responsive-preview ${isMobile ? 'is-mobile' : ''}`}>
      <div className="responsive-preview__header">
        <Flex justify="space-between">
          <T i18nKey="sidebar.task.metadata.modalpreview.preview.label" />
          <div className="responsive-preview__controls">
            <Button
              className={isMobile ? '' : 'is-active'}
              onClick={() => {
                setIsMobile(false)
              }}
              icon={<Icon icon={desktop} />}
            />
            <Button
              className={isMobile ? 'is-active' : ''}
              onClick={() => {
                setIsMobile(true)
              }}
              icon={<Icon icon={mobile} />}
            />
          </div>
        </Flex>
      </div>
      <div className="responsive-preview__preview">
        <p>{title}</p>
        <span>{link}</span>
        <p>{description}</p>
      </div>
    </article>
  )
}
