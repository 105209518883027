export const homeSchemaType = [
  'AnimalShelter',
  'ArchiveOrganization',
  'AutomotiveBusiness',
  'ChildCare',
  'Dentist',
  'DryCleaningOrLaundry',
  'EmergencyService',
  'EmploymentAgency',
  'EntertainmentBusiness',
  'FinancialService',
  'FoodEstablishment',
  'GovernmentOffice',
  'HealthAndBeautyBusiness',
  'HomeAndConstructionBusiness',
  'InternetCafe',
  'LegalService',
  'Library',
  'LodgingBusiness',
  'MedicalBusiness',
  'ProfessionalService',
  'RadioStation',
  'RealEstateAgent',
  'RecyclingCenter',
  'SelfStorage',
  'ShoppingCenter',
  'SportsActivityLocation',
  'Store',
  'TelevisionStation',
  'TouristInformationCenter',
  'TravelAgency',
]

export const pagesSchemaType = [
  'WebPage',
  'AboutPage',
  'FAQPage',
  'ContactPage',
]

export const postsSchemaType = ['Article', 'Product', 'Recipe', 'Event']
