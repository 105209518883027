const Image = ({ name, type, ...props }) => {
  const themeImgPath = window.marketgoo?.themeImgPath
  const src =
    {
      ['icons']: `${themeImgPath}/img/icons/${name}.svg`,
      ['illustrations']: `${themeImgPath}/illustrations/${name}.svg`,
    }[type] || `${themeImgPath}/img/${name}.svg`

  return <img src={src} {...props} />
}

export const IMAGES = {
  ICON: 'icon',
}

export const ICONS = {
  ANALYTICS_CONTENT: 'analytics-content',
  ANALYTICS_LINKS: 'analytics-links',
  ANALYTICS_TRAFFIC: 'analytics-traffic',
  BRONZE_MEDAL: 'bronze-medal',
  FIFTH_POSITION: 'fifth-position',
  FOURTH_POSITION: 'fourth-position',
  GOOGLE_ANALYTICS: 'google-analytics',
  KEYWORD: 'keyword',
  OPTIMIZE_PROGRESS: 'optimize-progress',
  PAGE_BLOCKED: 'page-blocked',
  PAGE_ERROR: 'page-error',
  SCAN: 'scan',
  SERP: 'serp',
  SILVER_MEDAL: 'silver-medal',
  TROPHY: 'trophy',
  WP_CLASSIC_EDITOR: 'wp-classic-editor',
  WRITING: 'writing',
  YOUR_COMPETITION: 'your-competition',
}

export const Icon = ({ className, name }) => (
  <Image className={className} type="icons" name={name} />
)

export const ILLUSTRATIONS = {
  ANALYZE: {
    COLLECTING_MONO: 'analyze/collecting-mono',
    DATA: 'analyze/data',
  },
  WIZARD: {
    WELCOME_WP: 'wizard/welcome-wp',
    WELCOME: 'wizard/wizard-welcome',
    END: 'wizard/wizard-end',
    STEPS: {
      COMPETITORS: {
        PRIMARY: 'wizard/steps/competitors/primary',
        SECONDARY: 'wizard/steps/competitors/1',
      },
      COUNTRY: {
        PRIMARY: 'wizard/steps/country/primary',
        SECONDARY: 'wizard/steps/country/1',
      },
      GOAL: {
        PRIMARY: 'wizard/steps/goal/primary',
        unselected: 'wizard/steps/goal/1',
        'promote-something': 'wizard/steps/goal/2',
        'facilitating-networking': 'wizard/steps/goal/3',
        multimedia: 'wizard/steps/goal/4',
        'share-news': 'wizard/steps/goal/5',
        'sell-things-online': 'wizard/steps/goal/6',
        other: 'wizard/steps/goal/7',
      },
      INDUSTRY: {
        PRIMARY: 'wizard/steps/industry/primary',
        unselected: 'wizard/steps/industry/1',
        finance: 'wizard/steps/industry/2',
        education: 'wizard/steps/industry/3',
        'tech-ecommerce': 'wizard/steps/industry/4',
        entertainment: 'wizard/steps/industry/5',
        'food-services': 'wizard/steps/industry/6',
        'real-estate': 'wizard/steps/industry/7',
        'healthcare-wellness': 'wizard/steps/industry/8',
        'travel-hospitality': 'wizard/steps/industry/9',
        'home-services': 'wizard/steps/industry/10',
        other: 'wizard/steps/industry/11',
      },
    },
  },
  OPTIMIZE: {
    MAIN: 'optimize/main',
    DASHBOARD: 'optimize/dashboard',
    PARTNER_WITH_MARKETGOO: 'optimize/partner-with-marketgoo',
    DIWM: 'optimize/diwm',
  },
  OYP: {
    OPTIMIZE: 'oyp/optimize',
    EMPTY: 'oyp/empty',
    EMPTY_MONO: 'oyp/empty-mono',
  },
  TASK: {
    NO404_FIXEDLINKS: 'task/fixedlinks',
    NO404_BROKENLINKS: 'task/brokenlinks',
    CONFIGURECOMP: 'task/configurecomp',
    DUPETITLE: 'task/dupetitle',
    SSLEXISTDESKTOP: 'task/sslexistdesktop',
    SSLEXISTDESKTOPOK: 'task/sslexistdesktopOK',
    SSLEXISTMOBILE: 'task/sslexistmobile',
    SSLEXISTMOBILEOK: 'task/sslexistmobileOK',
    ROBOTSTXT: 'task/robotstxt',
    ROBOTSTXTOK: 'task/robotstxt-ok',
    SITEMAP: 'task/sitemap',
  },
  SEARCH_APPEARANCE: { TOOLS: 'tools/easiest-seo-tool' },
  SETTINGS: { SETTINGS: 'settings/settings' },
  UPGRADE: {
    FROM: 'upgrade/product-from',
    TO: 'upgrade/product-to',
  },
}

export const Illustration = ({ className, name }) => (
  <Image className={className} type="illustrations" name={name} />
)

export default Image
