import { endpoints, request } from '#services'
import { GetPageError, GetPostError } from '#errors'

export const getPage = async (id) => {
  try {
    return await request(endpoints.pages.get(id))
  } catch (error) {
    throw GetPageError(error)
  }
}

export const getPost = async (id) => {
  try {
    return await request(endpoints.posts.get(id))
  } catch (error) {
    throw GetPostError(error)
  }
}

export const getPostThroughSlug = async (slug) => {
  try {
    return await request({ ...endpoints.pages.get(), ...{ params: { slug } } })
  } catch (error) {
    throw GetPostError(error)
  }
}

export const getPageThroughSlug = async (slug) => {
  try {
    return await request({ ...endpoints.posts.get(), ...{ params: { slug } } })
  } catch (error) {
    throw GetPageError(error)
  }
}
