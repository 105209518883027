import { chevronUp } from '@wordpress/icons'
import { Icon } from '@wordpress/components'

import './styles.css'

export const Details = ({ children, summary }) => {
  return (
    <details>
      <summary>
        <p>{summary}</p>
        <Icon className="chevron" icon={chevronUp} />
      </summary>
      {children}
    </details>
  )
}
