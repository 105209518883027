import { Check, ResponsivePreview, Show, TokenLabel } from '#components'
import { useMeta, usePop, useScan, useSearchAppearance } from '#hooks'
import { sendMetaTags } from '#services/metaTags'
import {
  Button,
  ButtonGroup,
  Flex,
  Modal,
  PanelBody,
  PanelRow,
  TextControl,
  TextareaControl,
} from '@wordpress/components'
import { useState } from '@wordpress/element'
import { Controller, useForm } from 'react-hook-form'
import { Trans as T, useTranslation } from 'react-i18next'
import { AIGenerationButton } from './AIGenerationButton'

import './styles.css'

export const MetaTags = ({ pop, isWaiting }) => {
  const { t } = useTranslation()

  const { data: keyword } = useMeta({
    popId: pop.id,
    key: 'keyword',
  })

  const {
    data: { title, description },
  } = useSearchAppearance({
    id: pop.id,
    type: pop.type,
  })

  const { data: scan, isValidating } = useScan(pop)
  const condition =
    scan?.tasks?.['keyontitle'].pass &&
    scan?.tasks?.['nodescsingle'].pass &&
    scan?.tasks?.['longtitlesingle'].pass &&
    scan?.tasks?.['notitlesingle'].pass

  const { data: post } = usePop(pop)

  const [isBusy, setIsBusy] = useState(false)

  const [showEditMetaTagsModal, setShowEditMetaTagsModal] = useState(false)
  const openEditMetaTagsModal = () => setShowEditMetaTagsModal(true)
  const closeEditMetaTagsModal = () => setShowEditMetaTagsModal(false)

  const [showPreviewMetaTagsModal, setShowPreviewMetaTagsModal] =
    useState(false)
  const openPreviewMetaTagsModal = () => setShowPreviewMetaTagsModal(true)
  const closePreviewMetaTagsModal = () => setShowPreviewMetaTagsModal(false)

  const META_TITLE_LIMIT = 70
  const META_DESCRIPTION_LIMIT = 160

  const { handleSubmit, control, watch, setValue } = useForm({
    values: { title, description },
    resetOptions: {
      keepDirtyValues: true,
    },
  })

  const showPreview = () => {
    closeEditMetaTagsModal()
    openPreviewMetaTagsModal()
  }

  const backToEdit = () => {
    closePreviewMetaTagsModal()
    openEditMetaTagsModal()
  }

  const onSubmit = (data) => {
    setIsBusy(true)
    sendMetaTags({ postId: pop.id, data })
      .then(() => {
        closeEditMetaTagsModal()
        closePreviewMetaTagsModal()
      })
      .finally(() => {
        setIsBusy(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <PanelBody
      title={
        <Flex justify="flex-start">
          <Check condition={condition} isValidating={isValidating} />
          <p className="task-title">{t('sidebar.task.metadata.title')}</p>
          <span className="ai-token">AI</span>
        </Flex>
      }
      initialOpen={!condition}
    >
      <PanelRow>
        <p className="task-content">
          {t(
            condition
              ? 'sidebar.task.metadata.completed'
              : 'sidebar.task.metadata.content1',
          )}
        </p>
        <Button
          isBusy={isBusy || isValidating || isWaiting.publishAndCheck}
          disabled={isBusy || isValidating || isWaiting.publishAndCheck}
          onClick={openEditMetaTagsModal}
          variant="primary"
        >
          {t('sidebar.task.metadata.button.edit')}
        </Button>
        <Show when={showEditMetaTagsModal}>
          <Modal
            title={t('sidebar.task.metadata.modaledit.title')}
            shouldCloseOnClickOutside={false}
            onRequestClose={closeEditMetaTagsModal}
            className="mktseo meta-tags"
          >
            <p>{t('sidebar.task.metadata.modaledit.content1')}</p>
            <ul>
              <li>
                {t('sidebar.task.metadata.modaledit.request1.keyword')}{' '}
                <TokenLabel>{keyword}</TokenLabel>
              </li>
              <li>{t('sidebar.task.metadata.modaledit.request2')}</li>
              <li>{t('sidebar.task.metadata.modaledit.request3')}</li>
            </ul>
            <form>
              <Controller
                name="title"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <TextControl
                    {...field}
                    maxLength={META_TITLE_LIMIT}
                    label={t(
                      'sidebar.task.metadata.modaledit.inputtitle.label',
                    )}
                    placeholder={t(
                      'sidebar.task.metadata.modaledit.inputtitle.placeholder',
                    )}
                    className={fieldState.invalid ? 'form-error' : ''}
                    help={
                      fieldState.error?.message ||
                      `${watch('title').length}/${META_TITLE_LIMIT}`
                    }
                  />
                )}
              />
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextareaControl
                    {...field}
                    maxLength={META_DESCRIPTION_LIMIT}
                    label={t(
                      'sidebar.task.metadata.modaledit.inputdescription.label',
                    )}
                    placeholder={t(
                      'sidebar.task.metadata.modaledit.inputdescription.placeholder',
                    )}
                    className={fieldState.invalid ? 'form-error' : ''}
                    help={
                      fieldState.error?.message ||
                      `${watch('description').length}/${META_DESCRIPTION_LIMIT}`
                    }
                  />
                )}
              />
              <ButtonGroup>
                <AIGenerationButton
                  setValue={setValue}
                  watch={watch}
                  pop={pop}
                />
              </ButtonGroup>
              <ButtonGroup>
                <Flex gap="5" justify="end">
                  <Button
                    isBusy={isBusy}
                    disabled={isBusy}
                    onClick={showPreview}
                    variant="secondary"
                  >
                    {t('sidebar.task.metadata.modaledit.button.preview')}
                  </Button>
                  <Button
                    isBusy={isBusy}
                    disabled={isBusy}
                    onClick={handleSubmit(onSubmit)}
                    variant="primary"
                  >
                    {t('sidebar.task.metadata.modaledit.button.apply')}
                  </Button>
                </Flex>
              </ButtonGroup>
            </form>
          </Modal>
        </Show>
        <Show when={showPreviewMetaTagsModal}>
          <Modal
            title={t('sidebar.task.metadata.modalpreview.title')}
            shouldCloseOnClickOutside={false}
            onRequestClose={closePreviewMetaTagsModal}
            className="mktseo meta-tags"
          >
            <p>
              <T
                i18nKey={'sidebar.task.metadata.modalpreview.content1'}
                components={[<b key={0} />]}
              />
            </p>
            <ResponsivePreview
              title={watch('title')}
              link={post?.link}
              description={watch('description')}
            />
            <p className="preview-description">
              {t('sidebar.task.metadata.modalpreview.preview.warning')}
            </p>
            <ButtonGroup>
              <Flex gap="5" justify="end">
                <Button
                  isBusy={isBusy}
                  disabled={isBusy}
                  onClick={backToEdit}
                  variant="secondary"
                >
                  {t('sidebar.task.metadata.modalpreview.preview.button.edit')}
                </Button>
                <Button
                  isBusy={isBusy}
                  disabled={isBusy}
                  onClick={handleSubmit(onSubmit)}
                  variant="primary"
                >
                  {t('sidebar.task.metadata.modalpreview.preview.button.apply')}
                </Button>
              </Flex>
            </ButtonGroup>
          </Modal>
        </Show>
      </PanelRow>
    </PanelBody>
  )
}
