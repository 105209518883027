import { Check } from '#components'
import { useScan } from '#hooks'
import { Flex, PanelBody, PanelRow } from '@wordpress/components'
import { useTranslation } from 'react-i18next'

import './styles.css'

export const PerfectTitle = ({ pop }) => {
  const { t } = useTranslation()

  const { data: scan, isValidating } = useScan(pop)

  const condition = scan?.tasks?.['keyonh1'].pass

  return (
    <PanelBody
      title={
        <Flex justify="flex-start">
          <Check condition={condition} isValidating={isValidating} />
          <p className="task-title">{t('sidebar.task.posttitle.title')}</p>
        </Flex>
      }
      initialOpen={!condition}
    >
      <PanelRow>
        <div className="perfect-title task-content">
          {condition ? (
            <p>{t('sidebar.task.posttitle.content.done')}</p>
          ) : (
            <>
              <p>{t('sidebar.task.posttitle.content1')}</p>
              <p>{t('sidebar.task.posttitle.content2')}</p>
              <p>{t('sidebar.task.posttitle.content3')}</p>
            </>
          )}
        </div>
      </PanelRow>
    </PanelBody>
  )
}
