import { Spinner } from '@wordpress/components'
import {
  cancelCircleFilled,
  helpFilled,
  plusCircleFilled,
} from '@wordpress/icons'

import './styles.css'

export const Check = ({ condition = false, isValidating = false }) =>
  isValidating ? (
    <Spinner />
  ) : (
    <span className={`check ${condition ? 'correct' : 'incorrect'}`} />
  )
